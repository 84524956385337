import { createRouter, createWebHistory, RouterView } from 'vue-router'
import Translation from '@/i18n/translation'
import { useCodelistStore } from '@/stores/codelist'

let codelist
const getCodelist = () => {
  codelist = useCodelistStore().codelist
}

const validateADFCyclesId = (to, from, next) => {
  getCodelist()
  const validIds = Object.keys(codelist?.adfCycles || {})
  if (validIds.includes(to.params.id)) {
    next()
  } else {
    next('/')
  }
}

const validateCountryId = (to, from, next) => {
  getCodelist()
  const countriesKeys = Object.keys(codelist?.countries || {})
  const regionsKeys = Object.keys(codelist?.regions || {})
  const validIds = countriesKeys.concat(regionsKeys)
  if (validIds.includes(to.params.id)) {
    next()
  } else {
    next('/')
  }
}

const validateSectorId = (to, from, next) => {
  getCodelist()
  const validIds = Object.keys(codelist?.sectors || {})
  if (validIds.includes(to.params.id)) {
    next()
  } else {
    next('/')
  }
}

const validateFinancingId = (to, from, next) => {
  getCodelist()
  const validIds = Object.keys(codelist?.organisations || {})
  if (validIds.includes(to.params.id)) {
    next()
  } else {
    next('/')
  }
}

const validateThemeId = (to, from, next) => {
  getCodelist()
  const validIds = Object.keys(codelist?.themes || {})
  if (validIds.includes(to.params.id)) {
    next()
  } else {
    next('/')
  }
}

const router = createRouter({
  history: createWebHistory(import.meta.env.VITE_BASE_URL),
  routes: [
    {
      path: '/:locale?',
      component: RouterView,
      beforeEnter: Translation.routeMiddleware,
      children: [
        {
          path: '',
          name: 'home',
          component: () => import('../views/HomeView.vue')
        },
        {
          path: 'adfcycles/:id',
          name: 'adfcycles',
          component: () => import('../views/ADFCyclesView.vue'),
          beforeEnter: validateADFCyclesId
        },
        {
          path: 'countries/:id',
          name: 'countries',
          component: () => import('../views/CountriesView.vue'),
          beforeEnter: validateCountryId
        },
        {
          path: 'sectors/:id',
          name: 'sectors',
          component: () => import('../views/SectorsView.vue'),
          beforeEnter: validateSectorId
        },
        {
          path: 'projects',
          children: [
            {
              path: '',
              name: 'projects',
              component: () => import('../views/ProjectsHomeView.vue')
            },
            {
              path: ':id',
              name: 'project',
              component: () => import('../views/ProjectsView.vue')
            }
          ]
        },
        {
          path: 'high5s/:id',
          name: 'high5s',
          component: () => import('../views/High5sView.vue'),
          beforeEnter: validateThemeId
        },
        {
          path: 'financing/:id',
          name: 'financing',
          component: () => import('../views/FinancingView.vue'),
          beforeEnter: validateFinancingId
        },
        {
          path: 'results',
          name: 'results',
          component: () => import('../views/ResultsView.vue')
        },
        {
          path: '/:locale?/:pathMatch(.*)*',
          redirect: '/'
        }
      ]
    }
  ]
})

const getPreferredLocale = () => {
  const storedLocale = localStorage.getItem('user-locale')
  if (storedLocale) {
    return storedLocale
  }

  const browserLocale = window.navigator.language || window.navigator.userLanguage || 'en'

  return {
    browserLocale,
    localeNoRegion: browserLocale.split('-')[0]
  }
}

router.beforeEach((to, from, next) => {
  if (to.path === '/') {
    const preferredLocale = getPreferredLocale()
    return next(`/${preferredLocale}`)
  }
  next()
})

export default router
