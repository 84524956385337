<template>
  <select @change="switchLanguage">
    <option
      v-for="lang in supportedLocales"
      :key="`lang-${lang}`"
      :value="lang"
      :selected="locale === lang"
    >
    {{ $t(`locale.${lang}`) }}
    </option>
  </select>
</template>

<script setup>
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'
import Translation from '@/i18n/translation'

const { locale } = useI18n()
const router = useRouter()
const supportedLocales = Translation.supportedLocales

const switchLanguage = async e => {
  const newLocale = e.target.value
  await Translation.switchLanguage(newLocale)

  try {
    await router.replace({ params: { locale: newLocale } })
  } catch (e) {
    console.error(e)
    router.push('/')
  }
}
</script>

<style lang="scss" scoped>
select {
  background: #0c7cac;
  border: none;
  border-radius: 0 0 8px 8px;
  color: #ffffff;
  cursor: pointer;
  font-size: 0.6rem;
  margin: 0;
  padding: 0.2rem 0.5rem;
  position: absolute;
  right: 2rem;
  top: 0;
  width: 5rem;

  &:hover, &:focus {
    background: #0c7cac;
    color: #ffffff;
  }

  svg {
    pointer-events: none;
  }
}
</style>
