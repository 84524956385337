<template>
  <select v-model="selectedOption" @change="switchCurrency">
    <option value="xdr">UA</option>
    <option value="usd">USD</option>
  </select>
</template>

<script setup>
import { ref } from 'vue'
import { useCurrencyStore } from '@/stores/currency'

const currencyStore = useCurrencyStore()
const selectedOption = ref(currencyStore.currency)

const switchCurrency = () => {
  localStorage.setItem('user-currency', selectedOption.value)
  currencyStore.currency = selectedOption.value
}
</script>

<style lang="scss" scoped>
select {
  background: #0c7cac;
  border: none;
  border-radius: 0 0 8px 8px;
  color: #ffffff;
  cursor: pointer;
  font-size: 0.6rem;
  margin: 0;
  padding: 0.2rem 0.5rem;
  position: absolute;
  right: 7.5rem;
  top: 0;

  &:hover, &:focus {
    background: #0c7cac;
    color: #ffffff;
  }

  svg {
    pointer-events: none;
  }
}
</style>
