<template>
  <nav>
    <div class="logo">
      <RouterLink :to="Translation.i18nRoute({ name: 'home' })" @click="navigateHome">
        <div class="afdb">
          <img class="afdb-logo" src="@/assets/afdb.svg" alt="AfDB logo">
          <span aria-hidden="true">{{ $t('nav.afdb') }}</span>
        </div>
        <span class="sr-only">{{ $t('nav.mapafrica') }}</span>
        <img class="mapafrica-logo" src="@/assets/logo.svg" alt="MapAfrica logo">
      </RouterLink>
    </div>
    <div class="mobile-menu">
      <button @click="toggleMenu('main', mainMenuOpen)">
        <FontAwesomeIcon class="icon-lg" :icon="['fas', 'bars']" />
      </button>
    </div>
    <ul :class="['main-menu', mainMenuOpen ? 'open' : 'closed']">
      <li>
        <RouterLink class="icon" :to="Translation.i18nRoute({ name: 'home' })" @click="navigateHome">
          <span class="sr-only">{{ $t('nav.home') }}</span>
          <FontAwesomeIcon :icon="['fas', 'home']" />
        </RouterLink>
      </li>
      <li>
        <button id="countries" @click="toggleMenu('countries', countriesMenuOpen)" :aria-expanded="countriesMenuOpen">
          {{ $t('nav.countries') }} <FontAwesomeIcon class="fa-sm" :icon="['fas', `${countriesMenuOpen ? 'chevron-up' : 'chevron-down'}`]" />
        </button>
        <ul :class="['countriesList', countriesMenuOpen ? 'open' : 'closed']">
          <li v-for="item in sortCountriesMenu" :key="item.id">
            <RouterLink :to="Translation.i18nRoute({ name: 'countries', params: { id: item.id }})">{{ item[locale] }}</RouterLink>
          </li>
          <li class="region" v-for="item in sortRegionsMenu" :key="item.id">
            <RouterLink :to="Translation.i18nRoute({ name: 'countries', params: { id: item.id }})">{{ item[locale] }}</RouterLink>
          </li>
          <li class="custom-region" v-for="item in sortCustomRegionsMenu" :key="item.id">
            <RouterLink :to="Translation.i18nRoute({ name: 'countries', params: { id: item.id }})">{{ item[locale] }}</RouterLink>
          </li>
        </ul>
      </li>
      <li>
        <button id="sectors" @click="toggleMenu('sectors', sectorsMenuOpen)" :aria-expanded="sectorsMenuOpen">
          {{ $t('nav.sectors') }} <FontAwesomeIcon class="fa-sm" :icon="['fas', `${sectorsMenuOpen ? 'chevron-up' : 'chevron-down'}`]" />
        </button>
        <ul :class="['sectorsList', sectorsMenuOpen ? 'open' : 'closed']">
          <li v-for="item in sortSectorsMenu" :key="item.id">
            <RouterLink :to="Translation.i18nRoute({ name: 'sectors', params: { id: item.id }})">{{ item[locale] }}</RouterLink>
          </li>
        </ul>
      </li>
      <li>
        <button id="high5s" @click="toggleMenu('high5s', themesMenuOpen)" :aria-expanded="themesMenuOpen">
          {{ $t('nav.high5s') }} <FontAwesomeIcon class="fa-sm" :icon="['fas', `${themesMenuOpen ? 'chevron-up' : 'chevron-down'}`]" />
        </button>
        <ul :class="['high5sList', themesMenuOpen ? 'open' : 'closed']">
          <li v-for="item in sortThemesMenu" :key="item.id">
            <RouterLink :to="Translation.i18nRoute({ name: 'high5s', params: { id: item.id }})">
              <span>
                <ThemeTag :code="item.id" />
              </span>
            </RouterLink>
          </li>
        </ul>
      </li>
      <li>
        <button id="financing" @click="toggleMenu('financing', financingMenuOpen)" :aria-expanded="financingMenuOpen">
          {{ $t('nav.financing') }} <FontAwesomeIcon class="fa-sm" :icon="['fas', `${financingMenuOpen ? 'chevron-up' : 'chevron-down'}`]" />
        </button>
        <ul :class="['financingList', financingMenuOpen ? 'open' : 'closed']">
          <li v-for="item in sortFinancingMenu" :key="item.id">
            <RouterLink :to="Translation.i18nRoute({ name: 'financing', params: { id: item.id }})">{{ item[locale] }}</RouterLink>
          </li>
        </ul>
      </li>
      <li>
        <button id="adfcycles" @click="toggleMenu('adfcycles', adfCyclesMenuOpen)" :aria-expanded="adfCyclesMenuOpen">
          {{ $t('nav.adfcycles') }} <FontAwesomeIcon class="fa-sm" :icon="['fas', `${adfCyclesMenuOpen ? 'chevron-up' : 'chevron-down'}`]" />
        </button>
        <ul :class="['adfCyclesList', adfCyclesMenuOpen ? 'open' : 'closed']">
          <li v-for="item in sortADFCyclesMenu" :key="item.id">
            <RouterLink :to="Translation.i18nRoute({ name: 'adfcycles', params: { id: item.id }})">{{ item.id }}</RouterLink>
          </li>
        </ul>
      </li>
      <li>
        <RouterLink :to="Translation.i18nRoute({ name: 'results' })">{{ $t('nav.results') }}</RouterLink>
      </li>
      <li>
        <RouterLink :to="Translation.i18nRoute({ name: 'projects' })">
          {{ $t('nav.projects') }}
          <FontAwesomeIcon :icon="['fas', 'magnifying-glass']" />
        </RouterLink>
      </li>
    </ul>
    <CurrencySwitcher />
    <LanguageSwitcher />
  </nav>
</template>

<script setup>
import { RouterLink, useRouter } from 'vue-router'
import { computed, onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faBars, faChevronDown, faChevronUp, faMagnifyingGlass, faHome } from '@fortawesome/free-solid-svg-icons'
import LanguageSwitcher from '@/components/LanguageSwitcher.vue'
import Translation from '@/i18n/translation'
import { storeToRefs } from 'pinia'
import { useCodelistStore } from '@/stores/codelist'

library.add(faBars, faChevronDown, faChevronUp, faMagnifyingGlass, faHome)

const { codelist } = storeToRefs(useCodelistStore())
const adfCyclesMenuOpen = ref(false)
const countriesMenuOpen = ref(false)
const financingMenuOpen = ref(false)
const themesMenuOpen = ref(false)
const mainMenuOpen = ref(false)
const sectorsMenuOpen = ref(false)

const adfCyclesList = ref({})
const countriesList = ref({})
const financingList = ref({})
const regionsList = ref({})
const sectorsList = ref({})
const themesList = ref({})

const breakPoint = 1450

const { locale } = useI18n()
const router = useRouter()

const sortADFCyclesMenu = computed(() => {
  let sortedList = []
  if (adfCyclesList.value) {
    sortedList = Object.entries(adfCyclesList.value).map(([key, value]) => {
      return {
        id: key,
        ...value
      }
    })
  }

  return sortedList.sort((a, b) => {
    return b.start_date.localeCompare(a.start_date)
  })
})

const sortCountriesMenu = computed(() => {
  let sortedList = []
  if (countriesList.value) {
    sortedList = Object.entries(countriesList.value).map(([key, value]) => {
      return {
        id: key,
        ...value
      }
    })
  }
  return sortedList.sort((a, b) => {
    return a[locale.value].localeCompare(b[locale.value])
  })
})

const sortFinancingMenu = computed(() => {
  let sortedList = []
  if (financingList.value) {
    sortedList = Object.entries(financingList.value).map(([key, value]) => {
      return {
        id: key,
        ...value
      }
    })
  }
  return sortedList.sort((a, b) => {
    return a[locale.value].localeCompare(b[locale.value])
  })
})

const sortRegionsMenu = computed(() => {
  let sortedList = []
  if (regionsList.value) {
    const list = regionsList.value
    sortedList = Object.entries(list).map(([key, value]) => {
      return {
        id: key,
        ...value
      }
    })
  }

  const filteredList = sortedList.filter(item => item.id.match(/RDG[A-Z]/))

  return filteredList.sort((a, b) => {
    return a[locale.value].localeCompare(b[locale.value])
  })
})

const sortCustomRegionsMenu = computed(() => {
  let sortedList = []
  if (regionsList.value) {
    const list = regionsList.value
    sortedList = Object.entries(list).map(([key, value]) => {
      return {
        id: key,
        ...value
      }
    })
  }

  const filteredList = sortedList.filter(item => item.id.match(/ADF|ADB|TSE/))

  return filteredList.sort((a, b) => {
    return a[locale.value].localeCompare(b[locale.value])
  })
})

const sortSectorsMenu = computed(() => {
  let sortedList = []
  if (sectorsList.value) {
    sortedList = Object.entries(sectorsList.value).map(([key, value]) => {
      return {
        id: key,
        ...value
      }
    })
  }
  return sortedList.sort((a, b) => {
    return a[locale.value].localeCompare(b[locale.value])
  })
})

const sortThemesMenu = computed(() => {
  const sortedList = Object.entries(themesList?.value || {}).map(([key, value]) => {
    return {
      id: key,
      ...value
    }
  })
  return sortedList.sort((a, b) => {
    return a.id - b.id
  })
})

const toggleMenu = (menu, state) => {
  state = !state

  switch (menu) {
    case 'adfcycles':
      adfCyclesMenuOpen.value = state
      countriesMenuOpen.value = false
      financingMenuOpen.value = false
      themesMenuOpen.value = false
      sectorsMenuOpen.value = false
      break
    case 'countries':
      adfCyclesMenuOpen.value = false
      countriesMenuOpen.value = state
      financingMenuOpen.value = false
      themesMenuOpen.value = false
      sectorsMenuOpen.value = false
      break
    case 'financing':
      adfCyclesMenuOpen.value = false
      countriesMenuOpen.value = false
      financingMenuOpen.value = state
      themesMenuOpen.value = false
      sectorsMenuOpen.value = false
      break
    case 'high5s':
      adfCyclesMenuOpen.value = false
      countriesMenuOpen.value = false
      financingMenuOpen.value = false
      themesMenuOpen.value = state
      sectorsMenuOpen.value = false
      break
    case 'main':
      adfCyclesMenuOpen.value = false
      countriesMenuOpen.value = false
      financingMenuOpen.value = false
      themesMenuOpen.value = false
      mainMenuOpen.value = state
      sectorsMenuOpen.value = false
      break
    case 'sectors':
      adfCyclesMenuOpen.value = false
      countriesMenuOpen.value = false
      financingMenuOpen.value = false
      themesMenuOpen.value = false
      sectorsMenuOpen.value = state
      break
  }
}

const closeAllMenus = () => {
  if (window.innerWidth > breakPoint) {
    adfCyclesMenuOpen.value = false
    countriesMenuOpen.value = false
    financingMenuOpen.value = false
    themesMenuOpen.value = false
    mainMenuOpen.value = true
    sectorsMenuOpen.value = false
  } else {
    adfCyclesMenuOpen.value = false
    countriesMenuOpen.value = false
    financingMenuOpen.value = false
    themesMenuOpen.value = false
    mainMenuOpen.value = false
    sectorsMenuOpen.value = false
  }
}

const navigateHome = (e) => {
  if (router.currentRoute.value.name === 'home') {
    // If already on the home page, refresh the page
    window.location.reload()
  }
}

router.beforeEach(() => {
  closeAllMenus()
})

onMounted(async () => {
  adfCyclesList.value = await codelist.value.adfCycles
  countriesList.value = await codelist.value.countries
  regionsList.value = await codelist.value.regions
  sectorsList.value = await codelist.value.sectors
  financingList.value = await codelist.value.organisations
  themesList.value = await codelist.value.themes
  mainMenuOpen.value = window.innerWidth > breakPoint

  window.addEventListener('resize', () => {
    closeAllMenus()
  })

  window.addEventListener('keydown', e => {
    if (e.key === 'Escape') {
      closeAllMenus()
    }
  })

  window.addEventListener('click', e => {
    if (e.target.id !== 'adfcycles') {
      adfCyclesMenuOpen.value = false
    }

    if (e.target.id !== 'countries') {
      countriesMenuOpen.value = false
    }

    if (e.target.id !== 'financing') {
      financingMenuOpen.value = false
    }

    if (e.target.id !== 'sectors') {
      sectorsMenuOpen.value = false
    }

    if (e.target.id !== 'high5s') {
      themesMenuOpen.value = false
    }
  })
})
</script>

<style lang="scss" scoped>
  nav {
    align-items: center;
    background: #ffffff;
    display: flex;
    flex-direction: row;
    height: 100px;
    justify-content: flex-end;
    margin: 0;
    min-width: 320px;
    padding: 0;
    position: relative;
    width: 100%;

    div.logo {
      position: absolute;
      height: 100px;
      left: 0;
      top: 0;

      a {
        display: block;
        padding: 1.2rem 0 0 1rem;
        text-decoration: none;
        height: 100%;

        div.afdb {
          display: flex;
          flex-direction: row;
          align-items: center;

          span {
            margin: -0.5rem 0 0 0;
          }

          img.afdb-logo {
            margin: 0 0.5rem 0.5rem 0;
            width: 40px;
          }
        }

        img.mapafrica-logo {
          width: 290px;
        }

        span {
          color: #66788f;
          display: block;
          font-size: 0.5rem;
          margin: 0;
          text-transform: uppercase;
        }

        &:hover {
          text-decoration: none;
          background-color: transparent;
        }
      }
    }

    div.mobile-menu {
      display: none;
      position: absolute;
      height: 100px;
      right: 0;
      top: 0;

      button {
        color: #2d4857;
        cursor: pointer;
        height: 100%;
        padding: 0 1rem;

        &:hover, &:focus {
          color: #0c7cac;
        }
      }
    }

    ul {
      align-items: stretch;
      display: flex;
      flex-direction: row;
      height: 100%;
      justify-content: flex-end;
      list-style: none;
      margin: 0 10px;
      padding: 0;
      position: relative;

      &.adfCyclesList, &.countriesList, &.langList, &.sectorsList, &.high5sList, &.financingList {
        align-content: flex-start;
        background: #ffffff;
        box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
        flex-direction: column;
        font-size: 0.6rem;
        height: max-content;
        justify-content: flex-start;
        max-height: 400px;
        overflow-y: auto;
        position: absolute;
        top: 100px;
        min-width: 150px;
        max-width: 350px;
        width: max-content;
        z-index: 99;

        li {
          &:nth-child(1 of .region),
          &:nth-child(1 of .custom-region) {
            margin: 1rem 0 0 0;
            position: relative;

            :before {
              background: #dcdfe6;
              content: "";
              height: 1px;
              left: 10%;
              pointer-events: none;
              position: absolute;
              top: -0.5rem;
              width: 80%;

            }
          }

          a {
            color: #0c7cac;
            padding: 4px 8px;
            font-size: 0.8rem;
            width: 100%;
            text-align: left;

            &:hover {
              color: #66788f;
              text-decoration: underline;
            }
          }
        }
      }

      li {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0;
        padding: 0;

        button {
          color: #0c7cac;
          font-size: 1rem;
          cursor: pointer;

          &:hover, &:focus {
            text-decoration: underline;
          }

          &.icon {
            color: #0c7cac;

            &:hover, &:focus {
              color: #0c7cac;
            }
          }

          svg {
            pointer-events: none;
          }
        }

        a {
          color: #0c7cac;
          font-size: 1rem;
          margin: 0;
          padding: 10px;
          text-align: center;
          text-decoration: none;

          svg {
            margin: 0 0 0 0.5rem;
          }

          &.icon {
            color: #0c7cac;

            &:hover {
              color: #0c7cac;
            }
          }

          &:hover {
            text-decoration: underline;
            color: #0c7cac;
          }
        }
      }
    }
  }

  button {
    border: none;
    background: none;
    color: #0c7cac;
    padding: 10px;

    .fa-sm {
      font-size: 0.6rem;
      margin: 0 0 2px 0;
    }

    .icon-lg {
      font-size: 1.6rem;
    }
  }

  @media screen and (max-width: 1450px) {
    nav {
      .main-menu {
        align-items: center;
        background: #ffffff;
        flex-direction: column;
        height: max-content;
        justify-content: flex-start;
        left: 0;
        margin: 0;
        position: absolute;
        top: 100px;
        width: 100%;
        z-index: 99;

        li {
          border-bottom: 1px solid #2d4857;
          display: flex;
          flex-direction: column;
          width: 100%;

          a, button {
            color: #0c7cac;
            width: 100%;

            &:hover, &:focus {
              background: #0c7cac;
              color: #ffffff;
            }

            &.icon {
              color: #0c7cac;

              &:hover, &:focus {
                color: #ffffff;
              }
            }
          }

          ul {
            &.adfCyclesList, &.countriesList, &.sectorsList, &.high5sList, &.financingList {
              border-top: 1px solid #66788f;
              box-shadow: none;
              top: 0;
              position: relative;
              width: 100%;
              max-width: 100%;

              li {
                border: none;

                a {
                  font-size: 1rem;
                  text-align: center;

                  &:hover, &:focus {
                    color: #ffffff;
                  }
                }

              }
            }
          }
        }
      }

      div.mobile-menu {
        display: block;
      }
    }
  }

  .open {
    display: flex;
  }

  .closed {
    display: none;
  }
</style>
