<template>
  <span class="high5-labels" :style="`color: ${themeColours[code]}`">
    <img :class="{lg: !text}" :src="getIconSrc(code)" alt="">
    <span v-if="text">{{ codelist?.themes[code][locale] }}</span>
  </span>
</template>

<script setup>
import { useI18n } from 'vue-i18n'
import { storeToRefs } from 'pinia'
import { useCodelistStore } from '@/stores/codelist'

const { codelist } = storeToRefs(useCodelistStore())

const { locale } = useI18n()
defineProps({
  code: String,
  text: {
    type: Boolean,
    default: true
  }
})

function getIconSrc (value) {
  const iconsMapping = {
    1: 'light-up-africa.svg',
    2: 'feed-africa.svg',
    3: 'industrialise-africa.svg',
    4: 'integrate-africa.svg',
    5: 'improve-africa.svg'
  }
  const theIcon = iconsMapping[value]
  return theIcon ? `/theme-icons/${theIcon}` : null
}

const themeColours = {
  1: '#6faa27',
  2: '#b9ac15',
  3: '#f07d00',
  4: '#219bd8',
  5: '#b71823'
}

</script>

<style lang="scss" scoped>
.high5-labels {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 2px auto 2px auto;
  pointer-events: none;
  text-align: left;

  img {
     width: 20px;
     display: inline;
     margin-right: 5px;

     &.lg {
      width: 3rem;
     }
  }
}
</style>
