import fetchData from '@/utils/fetchData'
import { defineStore } from 'pinia'

export const useCodelistStore = defineStore({
  id: 'codelist',
  state: () => ({
    codelist: {},
    indicators: []
  }),
  actions: {
    async fetchCodelist () {
      const codelists = await fetchData(`${import.meta.env.VITE_API_URL}/api/${import.meta.env.VITE_API_VERSION}/codelists`)
      this.codelist = codelists.codelists
    },
    async fetchIndicators () {
      const indicators = await fetchData(`${import.meta.env.VITE_API_URL}/api/results/indicators.json`)
      this.indicators = indicators
    }
  },
  persist: true
})
