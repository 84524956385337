<template>
  <nav>
    <ul>
      <li v-if="pathSegments[0] === locale || pathSegments[0] === ''">
        <RouterLink :class="`icon ${!pathSegments[1] && 'active'}`" :to="Translation.i18nRoute({ name: 'home' })" @click="navigateHome">
          <span class="sr-only">{{ $t('nav.home') }}</span>
          <FontAwesomeIcon :icon="['fas', 'home']" />
        </RouterLink>
      </li>
      <li v-if="pathSegments[1]">
        <FontAwesomeIcon :icon="['fas', 'chevron-right']" />
        <RouterLink :class="`${!title && 'active'}`" :to="Translation.i18nRoute({ name: pathSegments[1] })">{{ $t(`nav.${pathSegments[1]}`) }}</RouterLink>
      </li>
      <li v-if="title">
        <FontAwesomeIcon :icon="['fas', 'chevron-right']" />
        <RouterLink :class="`${title && 'active'}`" :to="route.fullPath">{{ title }}</RouterLink>
      </li>
    </ul>
  </nav>
</template>

<script setup>
import { RouterLink, useRoute, useRouter } from 'vue-router'
import Translation from '@/i18n/translation'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faHome, faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { useI18n } from 'vue-i18n'
import { onMounted, ref } from 'vue'
import fetchData from '@/utils/fetchData'
import { storeToRefs } from 'pinia'
import { useCodelistStore } from '@/stores/codelist'
import { useConfigStore } from '@/stores/config'

const { config } = storeToRefs(useConfigStore())
const { codelist } = storeToRefs(useCodelistStore())
const { locale } = useI18n()
const route = useRoute()
const router = useRouter()
const pathSegments = ref([])
const title = ref('')

library.add(faHome, faChevronRight)

const updatePathSegments = () => {
  pathSegments.value = route.path.split('/').filter(segment => segment !== '')
}

const getProjectName = async id => {
  const data = await fetchData(`${import.meta.env.VITE_API_URL}/api/${config.value.api_version}/activities/${id}?lang=${locale.value}`)
  return data.title
}

const setTitle = async () => {
  if (pathSegments.value[1] === 'adfcycles') {
    title.value = pathSegments.value[2]
  } else if (pathSegments.value[1] === 'countries') {
    title.value = pathSegments.value[2].match(/RDG[A-Z]|ADF|ADB|TSE/) ? codelist.value.regions[pathSegments.value[2]][locale.value] : codelist.value.countries[pathSegments.value[2]][locale.value]
  } else if (pathSegments.value[1] === 'sectors') {
    title.value = codelist.value.sectors[pathSegments.value[2]][locale.value]
  } else if (pathSegments.value[1] === 'financing') {
    title.value = codelist.value.organisations[pathSegments.value[2]][locale.value]
  } else if (pathSegments.value[1] === 'high5s') {
    if (codelist.value.themes) {
      title.value = codelist?.value?.themes[pathSegments.value[2]][locale.value]
    }
  } else if (pathSegments.value[1] === 'projects' && pathSegments.value[2]) {
    title.value = await getProjectName(pathSegments.value[2])
  } else {
    title.value = ''
  }
}

const navigateHome = (e) => {
  if (router.currentRoute.value.name === 'home') {
    // If already on the home page, refresh the page
    window.location.reload()
  }
}

onMounted(() => {
  updatePathSegments()
  setTitle()

  router.afterEach(() => {
    updatePathSegments()
    setTitle()
  })
})

</script>

<style lang="scss" scoped>
  nav {
    align-items: center;
    background: #0c7cac;
    color: #ffffff;
    display: flex;
    flex-direction: row;
    font-size: 0.8rem;
    height: 50px;
    justify-content: flex-start;
    margin: 0;
    padding: 0;
    width: 100%;

    ul {
      align-items: center;
      display: flex;
      flex-direction: row;
      height: 100%;
      justify-content: flex-start;
      list-style: none;
      margin: 0 1rem 0 10px;
      padding: 0;
      position: relative;
      width: calc(100% - 1rem);
      overflow: hidden;
      white-space: nowrap;

      li {
        &:last-of-type {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        a {
          color: #ffffff;
          font-size: 1rem;
          margin: 0;
          padding: 10px;
          text-align: center;
          text-decoration: none;
          opacity: 0.6;

          &.active {
            opacity: 1.0;
          }

          &.icon {
            svg {
              color: #ffffff;
              font-size: 1rem;
            }

            &:hover {
              color: #ffffff;
            }
          }

          &:hover {
            text-decoration: underline;
            color: #ffffff;
            opacity: 1.0;

            svg {
              color: #ffffff;
              font-size: 1rem;
              opacity: 1.0;
            }
          }
        }

        svg {
          &.fa-chevron-right {
            color: #ffffff;
            font-size: 0.8rem;
            opacity: 0.6;
          }
        }
      }
    }
  }
</style>
