import './assets/main.scss'

import { createApp } from 'vue'
import { createHead } from '@unhead/vue'
import { createPinia } from 'pinia'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
import FlagIcon from 'vue-flag-icon'
import { createPlausible } from 'v-plausible/vue'

import App from './App.vue'
import router from './router'
import i18n from './i18n'

import { useCodelistStore } from '@/stores/codelist'
import { useConfigStore } from '@/stores/config'

const app = createApp(App)
const head = createHead()
const pinia = createPinia()
const plausible = createPlausible({
  init: {
    domain: 'mapafrica.afdb.org',
    trackLocalhost: false
  },
  settings: {
    enableAutoOutboundTracking: true,
    enableAutoPageviews: true
  }
})

pinia.use(piniaPluginPersistedstate)

app.use(plausible)
app.use(FlagIcon)
app.use(pinia)
app.use(head)
app.use(router)
app.use(i18n)

const configStore = useConfigStore()
const codelistStore = useCodelistStore()

// Avoid using top-level await as it isn't widely supported
// Instead wrap the awaits in an IIFE
;(async () => {
  await configStore.fetchConfig()
  await codelistStore.fetchCodelist()
  await codelistStore.fetchIndicators()
  app.mount('#app')
})()
