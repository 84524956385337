import { defineStore } from 'pinia'

export const useLoadingStore = defineStore({
  id: 'loading',
  state: () => ({
    activitiesDataLoading: false,
    countriesDataLoading: false,
    financingDataLoading: false,
    locationDataLoading: false,
    resultsDataLoading: false,
    sectorsDataLoading: false,
    statsDataLoading: false,
    themesDataLoading: false
  }),
  actions: {
    setLoading (variable, value) {
      this[variable] = value
    }
  }
})
