import fetchData from '@/utils/fetchData'
import { defineStore } from 'pinia'
import { useCurrencyStore } from '@/stores/currency'

export const useConfigStore = defineStore({
  id: 'config',
  state: () => ({
    config: {}
  }),
  actions: {
    async fetchConfig () {
      const currencyStore = useCurrencyStore()
      this.config = await fetchData(`${import.meta.env.VITE_API_URL}/api/${import.meta.env.VITE_API_VERSION}/config?${currencyStore.currency}`)
    }
  },
  persist: true
})
