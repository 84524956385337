import { useLoadingStore } from '@/stores/loading'

const fetchData = async (url, loading) => {
  const loadingStore = useLoadingStore()

  if (loading) {
    loadingStore.setLoading(loading, true)
  }

  try {
    const response = await fetch(url)
    const data = await response.json()
    return data
  } catch {
    return []
  } finally {
    if (loading) {
      loadingStore.setLoading(loading, false)
    }
  }
}

export default fetchData
